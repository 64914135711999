@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/CentraNo2-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Centra No2";
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #163317;
  border-radius: 8px;
}
